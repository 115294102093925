<template>
  <div
    :class="{
      isLarge: store.$state.isLargeScreen,
      isSmall: !store.$state.isLargeScreen,
    }"
  >
    <div v-if="!externalTrigger" class="holder">
      <div
        class="hotspot relative flex cursor-pointer flex-col gap-2 text-white"
        style="pointer-events: all"
        :class="{ hover: hover }"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
        @click="showForm = !showForm"
      >
        <FontAwesomeIcon icon="comment" class="fa-icon" />
        <div
          class="option absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center gap-0 text-center"
        >
          CONTACT
        </div>
      </div>
    </div>
    <Dialog
      v-model:visible="showForm"
      header="Contact Pocket-E"
      :draggable="true"
      :keepInViewPort="true"
      :minX="0"
      :minY="0"
      :modal="true"
      :closable="true"
      class="bg-zinc-700"
    >
      <Contact
        class="m-4"
        style="max-width: 80vw"
        :subject="''"
        :source="'MAIN'"
        :useStatusEmits="true"
        @close="showForm = false"
        @success="handleSuccess"
        @fail="handleFail"
      />
    </Dialog>
    <Dialog
      v-model:visible="showSuccess"
      header="Message Received"
      :draggable="true"
      :keepInViewPort="true"
      :minX="0"
      :minY="0"
      :modal="true"
      :closable="true"
    >
      <div class="flex flex-col gap-0 p-1 pt-4 text-center">
        <div class="text-xl font-bold italic text-green-500">Thanks!</div>
        <div class="italic">We will respond shortly.</div>
      </div>
    </Dialog>
    <Dialog
      v-model:visible="showFail"
      header="Message Failed"
      :draggable="true"
      :keepInViewPort="true"
      :minX="0"
      :minY="0"
      :modal="true"
      :closable="true"
    >
      <div class="flex flex-col gap-1 p-2 pt-4 text-center">
        <div class="text-lg font-bold italic text-red-400">
          Message could not be sent.
        </div>
        <div class="italic">Please try again later.</div>
      </div>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

import Dialog from 'primevue/dialog';
import Contact from '@service/../theme/public/components/Contact.vue';

defineProps({
  externalTrigger: {
    type: Boolean,
    default: false,
  },
});

const showForm = defineModel({
  type: Boolean,
  default: false,
});

const showSuccess = ref<boolean>(false);
const showFail = ref<boolean>(false);
const hover = ref<boolean>(false);

import { useStore } from '@projroot/store';
const store = useStore();

onMounted(async () => {
  showSuccess.value = false;
  showFail.value = false;
  hover.value = false;
});

function handleSuccess() {
  showSuccess.value = true;
}

function handleFail() {
  showFail.value = true;
}
</script>

<style scoped lang="scss">
@keyframes throb {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

.isLarge {
  .holder {
    position: absolute;
    right: 2rem;
    /* use safe area inset to avoid overlap with mobile OS crap*/
    bottom: calc(env(safe-area-inset-bottom) + 1rem);
  }
}

.isSmall {
  .holder {
    position: absolute;
    left: 1rem;
    /* use safe area inset to avoid overlap with mobile OS crap*/
    top: calc(env(safe-area-inset-top) + 0.5rem);
  }
}

.fa-icon {
  transition: all 0.3s ease;
  fill: rgba(255, 255, 255, 0.5);
  animation: throb 2s infinite;
  font-size: 3rem;
  pointer-events: none;
}
.option {
  font-size: 0.6rem;
  font-weight: 900;
  transition: all 0.3s ease;
  text-align: center;
  pointer-events: none;
  user-select: none;
  color: #000;
}

.hover {
  cursor: pointer;
  .fa-icon {
    font-size: 4rem;
    animation: none;
    fill: rgba(255, 255, 255, 0.9);
  }
  .option {
    font-size: 0.8rem;
  }
}

:deep(.pi-envelope) {
  margin-right: 0.5rem;
}
</style>
