import app from './.make_app';

import { reactive } from 'vue';

import PrimeVue from 'primevue/config';

import Tooltip from 'primevue/tooltip';
import Ripple from 'primevue/ripple';
import BadgeDirective from 'primevue/badgedirective';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import DialogService from 'primevue/dialogservice';
import StyleClass from 'primevue/styleclass';

import HelpDirective from '@components/TheHelp/directive';

import Button from 'primevue/button';
import Calendar from 'primevue/datepicker';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/select';
import FloatLabel from 'primevue/floatlabel';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import RadioButton from 'primevue/radiobutton';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import VueTippy from 'vue-tippy';

import DocImg from '@components/DocImage';
import DocLink from '@components/DocLink';
import SLSCheckbox from '@components/SLSCheckbox';
import GQLDropdown from '@components/GQLDropdown';
import GQLTable from '@components/GQLTable';
import ValidatedFormField from '@components/ValidatedFormField';
import ValidatedInputDate from '@components/ValidatedInputDate';
import ValidatedInputDateTime from '@components/ValidatedInputDateTime';
import ValidatedInputNumber from '@components/ValidatedInputNumber';
import ValidatedInputText from '@components/ValidatedInputText';
import FormField from '@components/FormField';
import FormModal from '@components/FormModal';

import './styles/main.css';
import 'primeicons/primeicons.css';

import 'prismjs/themes/prism-coy.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';

import '@vue-flow/core/dist/style.css';
import '@vue-flow/core/dist/theme-default.css';
import '@vue-flow/node-resizer/dist/style.css';

import '@he-tree/vue/style/default.css';

import { OverlaySpinnerService } from '@components/OverlaySpinner';

import { createPinia } from 'pinia';
import piniaPluginPersitedState from 'pinia-plugin-persistedstate';
// preload chartjs plugin(s)
//@ts-ignore
import type { TooltipPositionerFunction, ChartType } from 'chart.js';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(annotationPlugin);
declare module 'chart.js' {
  // Extend tooltip positioner map
  interface TooltipPositionerMap {
    cursor: TooltipPositionerFunction<ChartType>;
  }
}

app.config.globalProperties.$appState = reactive({
  inputStyle: 'outlined',
  theme: 'dark',
});

app.use(PrimeVue, {
  theme: 'none',
});

// app.use(PrimeVue, { unstyled: true });
app.use(ConfirmationService);
app.use(ToastService);
app.use(DialogService);
app.use(VueTippy, {
  defaultProps: {
    theme: 'legit',
  },
});

const pinia = createPinia();
pinia.use(piniaPluginPersitedState);
app.use(pinia);

app.use(OverlaySpinnerService);

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('badge', BadgeDirective);
app.directive('styleclass', StyleClass);
app.directive('help', HelpDirective);

app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Dialog', Dialog);
app.component('Dropdown', Dropdown);
app.component('FloatLabel', FloatLabel);
app.component('InputGroup', InputGroup);
app.component('InputGroupAddon', InputGroupAddon);
app.component('InputNumber', InputNumber);
app.component('InputText', InputText);
app.component('RadioButton', RadioButton);
app.component('TextArea', TextArea);

app.component('DataTable', DataTable);
app.component('Column', Column);

app.component('GQLDropdown', GQLDropdown);
app.component('GQLTable', GQLTable);
app.component('FormField', FormField);
app.component('FormModal', FormModal);
app.component('Checkbox', SLSCheckbox);
app.component('ValidatedFormField', ValidatedFormField);
app.component('ValidatedInputDate', ValidatedInputDate);
app.component('ValidatedInputDateTime', ValidatedInputDateTime);
app.component('ValidatedInputNumber', ValidatedInputNumber);
app.component('ValidatedInputText', ValidatedInputText);

app.component('CookieAcceptDecline', VueCookieAcceptDecline);
app.component('DocImg', DocImg);
app.component('DocLink', DocLink);

app.mount('#app');
