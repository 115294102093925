<template>
  <div class="flex h-screen flex-col">
    <!-- Header -->
    <header class="flex items-center justify-between bg-gray-900 px-4 py-2 shadow-md">
      <a class="flex items-center gap-3" href="/">
        <img class="h-12" src="/pe_logo.png" alt="Pocket-E Logo" />
        <div class="hidden text-4xl font-bold text-neutral-100 sm:block">Pocket-E</div>
      </a>
      <div class="flex items-center gap-3">
        <div class="text-3xl italic text-neutral-100">Use Cases</div>
        <button
          class="text-4xl text-white lg:hidden"
          @click="isSidebarOpen = !isSidebarOpen"
        >
          ☰
        </button>
      </div>
    </header>

    <!-- Content Area -->
    <div class="flex flex-1 overflow-hidden border-y-2 border-gray-600">
      <!-- Main Content Area -->
      <main
        ref="contentArea"
        class="prose prose-invert max-w-none flex-1 overflow-y-auto p-0 md:px-12 md:py-6"
      >
        <component
          v-if="currentComponent"
          :is="currentComponent"
          :title="useCases[selectedIndex]?.title"
        />
        <div v-else class="my-4 flex w-full flex-col gap-3 text-center text-red-500">
          <div class="text-4xl font-bold">
            The use case you’re looking for does not exist.
          </div>
          <div class="text-2xl italic">
            Please select one from the menu on the right or return to our
            <a href="/" class="text-yellow-500 underline">homepage</a>.
          </div>
        </div>
      </main>

      <!-- Right Sidebar -->
      <Transition>
        <div
          class="absolute inset-0 block bg-black/80 lg:hidden"
          v-if="isSidebarOpen"
          @click="isSidebarOpen = false"
        ></div>
      </Transition>
      <aside
        :class="[
          'fixed right-0 top-0 h-full bg-gray-800 text-white transition-transform lg:relative lg:h-auto',
          isSidebarOpen ? 'translate-x-0' : 'translate-x-full lg:translate-x-0',
        ]"
        class="w-80 overflow-y-auto shadow-md lg:shadow-none"
      >
        <ul class="divide-y divide-gray-700">
          <li
            v-for="(useCase, index) in useCases"
            :key="index"
            @click="navigateToUseCase(useCase.slug, index)"
            class="flex min-h-16 cursor-pointer items-center p-4 transition-all duration-300 hover:bg-gray-700"
            :class="{ 'bg-gray-700': selectedIndex === index }"
          >
            {{ useCase.title }}
          </li>
        </ul>
      </aside>
    </div>

    <!-- Footer -->
    <footer class="bg-gray-950 px-4 py-2">
      <Footer />
    </footer>
  </div>
</template>

<script setup lang="ts">
import { shallowRef, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Footer from './Footer.vue';

const router = useRouter();
const route = useRoute();

const useCases = [
  {
    title: 'Automated ERP Transactions',
    slug: 'automated-erp-transaction-integration',
  },
  {
    title: 'Enhanced Manual Backflush System',
    slug: 'enhanced-manual-backflush-system',
  },
  {
    title: 'Kanban-Based Logistics Management',
    slug: 'kanban-based-logistics-management-system',
  },
  {
    title: 'Real-Time Inventory Management',
    slug: 'real-time-inventory-management-system',
  },
  { title: 'QA Hold and Disposition System', slug: 'qa-hold-and-disposition-system' },
  {
    title: 'Maintenance and Service Tickets ',
    slug: 'maintenance-and-service-ticket-system',
  },
  { title: 'Scan-to-Pack Shipping System', slug: 'scan-to-pack-shipping-system' },
  {
    title: 'Automated WIP Inventory Labeling',
    slug: 'automated-wip-inventory-labeling-system',
  },
  {
    title: 'Machine Metrics Monitoring System',
    slug: 'machine-metrics-monitoring-system',
  },
  { title: 'Component Lot Tracking System', slug: 'component-lot-tracking-system' },
  {
    title: 'Overall Equipment Effectiveness (OEE)',
    slug: 'oee-analytics-system-efiato',
  },
];

const selectedIndex = shallowRef(0);
const currentComponent = shallowRef(null);
const isSidebarOpen = shallowRef(false);
const contentArea = shallowRef<HTMLElement | null>(null);

async function loadComponent(slug: string) {
  const index = useCases.findIndex((useCase) => useCase.slug === slug);
  if (index !== -1) {
    try {
      const component = await import(`./cases/${slug}.vue`);
      selectedIndex.value = index;
      currentComponent.value = component.default || component;

      // Scroll to the top of the content area
      if (contentArea.value) {
        contentArea.value.scrollTop = 0;
      }
    } catch (err) {
      console.error('Failed to load component:', err);
      currentComponent.value = null;
    }
  } else {
    selectedIndex.value = -1; // Indicate invalid slug
    currentComponent.value = null; // No component found
  }
}

function navigateToUseCase(slug: string, index: number) {
  selectedIndex.value = index;
  isSidebarOpen.value = false;
  router.push({ path: `/use/${slug}` });
  loadComponent(slug);
}

watch(
  () => {
    const slug = Array.isArray(route.params.slug)
      ? route.params.slug[0]
      : route.params.slug;
    return slug || useCases[0].slug;
  },
  (slug) => {
    loadComponent(slug);
  },
  { immediate: true }
);

onMounted(() => {
  const slug = Array.isArray(route.params.slug)
    ? route.params.slug[0]
    : route.params.slug || useCases[0].slug;
  loadComponent(slug);
});
</script>
