<template>
  <div class="flex w-full flex-col gap-4 pt-2 text-black dark:text-white">
    <div v-if="!lottaRowMode">
      <div class="ml-1 text-xs font-bold">Page Size</div>
      <Dropdown
        class="w-full"
        v-model="mutatablePageSize"
        :options="pageSizes"
        :disabled="useGlobalPageSize"
      />
    </div>

    <div
      v-if="!preventRealtime"
      class="flex h-13 items-center gap-2 bg-surface-100 p-3 dark:bg-surface-900"
    >
      <Checkbox inputId="realtime" v-model="mutatableRealtime" />
      <label for="realtime" class="ml-1 text-lg">Show Realtime Updates</label>
    </div>

    <div v-if="state.columns.length === 0">
      <span>No columns on this table</span>
    </div>
    <div v-else class="flex flex-col">
      <div class="ml-1 text-xs font-bold">Visible Column</div>
      <div
        class="flex flex-col gap-4 overflow-y-auto bg-surface-100 p-3 dark:bg-surface-900"
        style="max-height: 50vh"
      >
        <template v-for="column in state.columns" :key="column.field">
          <div v-if="column.column_type != ColType.hidden" class="flex gap-2">
            <Checkbox
              :modelValue="!column.hidden"
              :inputId="column.field"
              @change="checkboxChange(column)"
            />
            <label :for="column.field">
              {{ c.helpers.getColDefHeader(column) }}
            </label>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref, watch } from 'vue';

import { ColDef, ColType, GQLState, TableClass } from '@service/gql';

import useCore from '@service/core';
const c = useCore();

const state = defineModel({
  type: Object as PropType<GQLState<any>>,
  required: true,
});

defineProps({
  preventRealtime: {
    type: Boolean,
    required: true,
  },
  lottaRowMode: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['close']);

function checkboxChange(column: ColDef<TableClass>) {
  console.log('checkboxChange', column);
  column.hidden = !column.hidden;
}

const pageSizes = ref([10, 25, 50, 100]);
const mutatablePageSize = ref(state.value.limit);
const mutatableRealtime = ref(state.value.realtime);

const useGlobalPageSize = ref(false);

watch(
  () => mutatablePageSize.value,
  async (cur, old) => {
    if (cur != old) {
      state.value.limit = mutatablePageSize.value;
      state.value.offset = 0;
      await state.value.doRows();
    }
  }
);

watch(
  () => mutatableRealtime.value,
  async (cur, old) => {
    if (cur != old) {
      state.value.realtime = mutatableRealtime.value;
      await state.value.refresh();
    }
  }
);
</script>

<style scoped lang="scss"></style>
