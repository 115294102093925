<template>
  <Button
    v-if="hidden || (!hidden && !button.disabledEuids)"
    :severity="button.severity"
    rounded
    :disabled="isDisabled"
    @click="button.clickCallback(rows[rowIdx][dataKey], rows[rowIdx])"
    class="min-h-12 min-w-12"
  >
    <div class="-m-2 flex min-h-8 min-w-8 items-center justify-center gap-1">
      <template v-if="button.iconClass.startsWith('pi')">
        <i :class="button.iconClass"></i>
      </template>
      <template v-else-if="button.iconClass.startsWith('fa')">
        <FontAwesomeIcon :icon="button.iconClass" class="pb-1" />
      </template>
      <template v-if="button.label && button.label.length">
        <div class="text-sm" style="white-space: nowrap">{{ button.label }}</div>
      </template>
    </div>
  </Button>
</template>

<script setup lang="ts">
import { PropType, ref, onMounted, computed } from 'vue';

import { ButtonDef } from '../buttons';

const props = defineProps({
  rowIdx: {
    type: Number,
    required: true,
  },
  button: {
    type: Object as PropType<ButtonDef>,
    default: null,
  },
  rows: {
    type: Object as PropType<Array<Object>>,
    required: true,
  },
  dataKey: {
    type: String,
    default: 'id',
  },
});

const hidden = ref<boolean>(true);

onMounted(() => {
  if (props.button.disabledEuids) {
    props.button.disabledEuids.forEach((euid) => {
      if (euid == props.rows[props.rowIdx]['euid']) hidden.value = false;
    });
  }
});

const isDisabled = computed(() => {
  return (
    props.button.buttonEnabledCallback !== undefined &&
    !props.button.buttonEnabledCallback(props.rows[props.rowIdx])
  );
});
</script>

<style scoped lang="scss"></style>
