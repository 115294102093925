<template>
  <input type="date" :class="inputClasses" :value="dateString" @input="onInput" />
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';

import { safeDate } from '@service/helpers';

import { useDynamicClasses } from '@composables/input_class';

const props = defineProps({
  modelValue: {
    type: [String, Date],
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const invalid = ref(false);

function onInput(event) {
  const curValue = event.target.value;
  const dval = safeDate(curValue.toString());
  if (dval instanceof Date && !isNaN(dval.getTime())) {
    invalid.value = false;
    emit('update:modelValue', curValue);
    dateString.value = event.target.value;
  } else invalid.value = true;
}

const dateString = ref('');

onMounted(() => setMe());

watch(
  () => props.modelValue,
  (cur, old) => setMe()
);

function setMe() {
  if (props.modelValue) {
    if (typeof props.modelValue === 'string')
      dateString.value = props.modelValue.split('T')[0];
    else dateString.value = props.modelValue.toISOString().split('T')[0];
  }
}

const inputClasses = useDynamicClasses({ invalid, disabled: ref(props.disabled) });
</script>

<style lang="scss" scoped>
/*
input[type='date']::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  border-width: thin;
}
*/

input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 1rem;
}
input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 1rem;
}
input::-webkit-datetime-edit {
  position: relative;
  right: 1rem;
}
</style>
