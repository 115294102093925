import { DirectiveBinding } from 'vue';

interface HelpRequest {
  key?: string;
  title?: string;
  body?: string;
  color?: HelpColor;
}

interface HelpData extends HelpRequest {
  id: string;
  color: HelpColor;
}

export enum HelpColor {
  RED,
  GREEN,
  BLUE,
  YELLOW,
}

export interface HelpElement {
  element: HTMLElement;
  data: HelpData;
}

export const helpElements: Array<HelpElement> = [];

function generateRandomUID(): string {
  return Math.random().toString(36).substr(2, 9);
}

export default {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    let res: HelpData = {
      id: generateRandomUID(),
      key: undefined,
      title: undefined,
      body: undefined,
      color: HelpColor.YELLOW,
    };

    if (typeof binding.value === 'string') {
      res.key = binding.value;
    } else if (typeof binding.value === 'object' && binding.value !== null) {
      const helpGuts = binding.value as HelpData;

      // we either need a key and no title/body or a title/body and no key
      if (!helpGuts.key && !helpGuts.title && !helpGuts.body) {
        throw new Error('You must provide a key or a title/body');
      }
      if (helpGuts.key && (helpGuts.title || helpGuts.body)) {
        throw new Error('You cannot provide a key and a title/body');
      }
      if (helpGuts.title && !helpGuts.body) {
        throw new Error('You must provide a body if you provide a title');
      }
      if (helpGuts.body && !helpGuts.title) {
        throw new Error('You must provide a title if you provide a body');
      }

      res.key = helpGuts.key;
      res.title = helpGuts.title;
      res.body = helpGuts.body;
      if (helpGuts.color !== undefined) res.color = helpGuts.color;
    }

    helpElements.push({ element: el, data: res });
  },

  unmounted(el: HTMLElement) {
    const key = el.dataset.helpKey;
    if (key) {
      const index = helpElements.findIndex((item) => item.element === el);
      if (index !== -1) {
        helpElements.splice(index, 1); // Remove the element from the array
      }
    }
  },
};
