<template>
  <div
    class="flex flex-1 items-center justify-center"
    :class="{
      isLarge: store.$state.isLargeScreen,
      isSmall: !store.$state.isLargeScreen,
    }"
  >
    <div
      class="guts my-0 flex flex-col items-center justify-center gap-4 px-4 py-10 sm:px-10 xl:mx-4 xl:flex-row xl:gap-10 xl:p-10"
      :class="[store.$state.isLargeScreen ? 'my-4' : '']"
    >
      <div class="flex min-h-[28rem] flex-col items-center justify-start">
        <img
          src="/pe_logo.png"
          alt="Pocket-E Logo"
          class="mb-1"
          style="width: 300px; height: 300px"
        />
        <div class="xxtitle xxoutline">Pocket-E</div>
        <div class="caption xxoutline flex">
          <div id="textContainer" ref="textContainer"></div>
          <div class="relative">
            <div v-if="isQuestion" class="promptBox question">?</div>
            <div v-if="isChecked" class="promptBox">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 67.733332 67.733333"
                class="-mt-2 h-12 w-12"
              >
                <path
                  d="M55.63 5.18L28.56 40.34 12.67 23.85 1.2 34.89C9.24 43.15 29.75 62.55 29.75 62.55L66.54 20.93Z"
                  fill="#00f000"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div
        class="text-full flex max-w-[38rem] flex-col gap-4 text-2xl text-neutral-100"
      >
        <div
          class="mb-4 text-center text-[2.8rem] font-bold italic leading-10 md:text-left"
        >
          Your Smart Factory Solution
        </div>
        <div>
          Pocket-E helps manufacturers automate, integrate, and innovate. We take all
          the buzzwords—Industry 4.0, OEE, IT/OT, etc.—and turn them into real, working
          solutions.
        </div>
        <div>
          Our platform empowers you to move faster, work smarter, and consistently
          deliver exceptional results to your customers.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';

import { useStore } from '@projroot/store';
const store = useStore();

const textContainer = ref<HTMLElement>();
const isChecked = ref<boolean>(false);
const isQuestion = ref<boolean>(false);
let timeout = 0;

onMounted(async () => {
  const phrases = [
    'Smart Factory Platform',
    'Autonomous Systems',
    'Industrial IoT',
    'Real-Time OOE & OEE',
    'IT / OT Convergence',
    'Industry 4.0',
  ];

  let currentPhrase = 0;
  let currentLetter = 0;

  function typePhrase() {
    if (!textContainer.value) {
      console.error('No text container found');
      return;
    }

    if (currentLetter < phrases[currentPhrase].length) {
      textContainer.value.textContent += phrases[currentPhrase].charAt(currentLetter);
      currentLetter++;
      if (currentLetter == phrases[currentPhrase].length)
        timeout = window.setTimeout(typePhrase, 400);
      else timeout = window.setTimeout(typePhrase, 100);
    } else if (currentLetter == phrases[currentPhrase].length) {
      currentLetter++;
      timeout = window.setTimeout(typePhrase, 2000);
      isQuestion.value = true;
    } else {
      isQuestion.value = false;
      isChecked.value = true;
      timeout = window.setTimeout(clearPhrase, 3000);
    }
  }

  function clearPhrase() {
    if (!textContainer.value) {
      console.error('No text container found');
      return;
    }
    isChecked.value = false;

    textContainer.value.textContent = '';
    currentLetter = 0;
    currentPhrase = (currentPhrase + 1) % phrases.length;
    timeout = window.setTimeout(typePhrase, 500);
  }

  typePhrase();
});

onUnmounted(() => {
  if (timeout) clearTimeout(timeout);
});
</script>

<style scoped lang="scss">
.xxtitle {
  font-size: 4.2rem;
  line-height: 5.5rem;
  font-weight: 800;
  color: rgba(255, 255, 255, 0.9);
}
.xxoutline {
  text-shadow:
    -1px -1px 0 black,
    1px -1px 0 black,
    -1px 1px 0 black,
    1px 1px 0 black;
}

#textContainer {
  font-size: 1.5rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  margin-right: 2rem;

  /*
  font-family: 'Arial', sans-serif;
  font-size: 20px;
  color: #333;
  */
}

.promptBox {
  position: absolute;
  top: 0;
  left: -2rem;
  height: 100%;
  line-height: 1.5rem;
  margin-left: 0.5rem;

  &.question {
    color: rgb(223, 223, 0) !important;
    font-size: 3.2rem;
    font-weight: 900;
  }
}

.isLarge {
  .guts {
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 1rem;
    border: 2px solid rgba(255, 255, 255, 0.7);
  }
}

.isSmall {
  .guts {
    background-color: rgba(0, 0, 0, 0.9);
    border-top: 2px solid rgba(255, 255, 255, 0.7);
    border-bottom: 2px solid rgba(255, 255, 255, 0.7);
    width: 100%;
  }
}
</style>
