<template>
  <th @click="$emit('headerClick', column)" class="bg-primary text-primary-contrast">
    <div class="flex">
      <span>
        {{ c.helpers.getColDefHeader(column) }}
      </span>
      <div class="flex flex-1 justify-between">
        <span class="icon-container ml-2" @click.stop="$emit('sortClick', column)">
          <i class="pi" :class="getSortClass()" />
        </span>
        <span
          v-if="!noFilter"
          class="icon-container ml-2 mr-1"
          :class="isActiveFilter()"
          @click.stop="$emit('filterClick', column)"
        >
          <i class="pi pi-filter" />
        </span>
      </div>
    </div>
  </th>
</template>

<script setup lang="ts">
import { PropType } from 'vue';

import { GQLState, ColDef, OrderBy, ColType, TableClass } from '@service/gql';

import useCore from '@service/core';
const c = useCore();

const state = defineModel({
  type: Object as PropType<GQLState<any>>,
  required: true,
});

const props = defineProps({
  column: {
    type: Object as PropType<ColDef<TableClass>>,
    required: true,
  },
  noFilter: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['headerClick', 'sortClick', 'filterClick']);

function getSortClass() {
  const activeOrder = state.value.getOrderBy(props.column.field);
  if (activeOrder) {
    if (
      props.column.column_type === undefined ||
      props.column.column_type === ColType.string
    ) {
      return activeOrder == OrderBy.asc
        ? 'pi-sort-alpha-down'
        : 'pi-sort-alpha-down-alt';
    }
    return activeOrder == OrderBy.asc
      ? 'pi-sort-amount-down-alt'
      : 'pi-sort-amount-down';
  }
  return 'pi-sort-alt';
}

function hasFiltersForField(newLocalWhere, field) {
  if (newLocalWhere !== undefined && newLocalWhere['_and']) {
    return newLocalWhere['_and'].some((filter) => {
      if (filter[field] !== undefined) {
        return true;
      }
      if (filter['_or']) {
        return filter['_or'].some((orFilter) => orFilter[field] !== undefined);
      }
      return false;
    });
  }
  return false;
}

function isActiveFilter() {
  const where = state.value.localWhere;
  if (hasFiltersForField(where, props.column.field)) {
    return 'active-filter';
  }
  return 'non-active-filter';
}
</script>

<style scoped lang="scss">
th {
  white-space: nowrap;
  transition: 0.2s;
  cursor: pointer;
  overflow: hidden;
  vertical-align: middle;
  position: relative;

  span {
    font-size: 0.8rem;
    font-weight: 400;
    vertical-align: middle;
    &.icon-container {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-top: 0.05rem;
      cursor: pointer;
      height: calc(100% - 1px);
      position: relative;
      &:before {
        content: '';
        transition: 0.2s;
        border-radius: 50%;
        background-color: transparent;
        position: absolute;
        top: calc(50% - 1px);
        left: 50%;
        transform: translate(-50%, -50%);
        height: calc(100% - 1px);
        width: 100%;
        padding: 0.9rem;
      }
      &:hover {
        &:before {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }

      &.active-filter {
        &:before {
          animation: pulse 2s infinite;
          border: 3px solid var(--p-content-border-color);
          opacity: 0.6;
          padding: 0.75rem;
          margin-top: 0.03rem;
        }
      }

      i {
        font-size: 1rem;
        line-height: 1;
      }
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
</style>
