import { TabState, HelpMode } from './tabState';

export interface TabActions {
  showHelpModal(page?: string, anchor?: string): void;
  hideHelp(): void;
}

type StoreStateActions = TabState & TabActions;

export const baseTabActions: TabActions = {
  showHelpModal(this: StoreStateActions, page?: string, anchor?: string) {
    this.helpPage = page || '';
    this.helpAnchor = anchor || '';
    this.helpMode = HelpMode.MODAL;
  },
  hideHelp(this: StoreStateActions) {
    this.helpPage = '';
    this.helpAnchor = '';
    this.helpMode = HelpMode.INACTIVE;
  },
};
