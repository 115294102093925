<template>
  <input
    type="datetime-local"
    :class="inputClasses"
    :value="dateString"
    :step="stepSize"
    @input="onInput"
  />
</template>

<script setup lang="ts">
import { computed, ref, onMounted, watch } from 'vue';
import { useDynamicClasses } from '@composables/input_class';

const props = defineProps({
  modelValue: {
    type: Date,
    default: undefined,
  },
  hasSeconds: {
    type: Boolean,
    deafult: false,
  },
  invalid: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const invalid = ref(false);

function onInput(event) {
  const curValue = new Date(event.target.value);
  emit('update:modelValue', curValue);
  dateString.value = event.target.value;
}

const stepSize = computed(() => {
  return props.hasSeconds ? 1 : 60;
});

const dateString = ref('');

onMounted(() => {
  setValue();
});

watch(
  () => props.modelValue,
  (cur, old) => {
    setValue();
  }
);

function setValue() {
  if (props.modelValue) {
    let c = new Date(props.modelValue);
    dateString.value =
      c.getFullYear() +
      '-' +
      ('0' + (c.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + c.getDate()).slice(-2) +
      'T' +
      ('0' + c.getHours()).slice(-2) +
      ':' +
      ('0' + c.getMinutes()).slice(-2) +
      ':' +
      ('0' + c.getSeconds()).slice(-2);
  }
}

const inputClasses = useDynamicClasses({ invalid, disabled: ref(props.disabled) });
</script>
