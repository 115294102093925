export enum HelpMode {
  INACTIVE = 'INACTIVE',
  MODAL = 'MODAL',
  TOOLTIP = 'TOOLTIP',
}

export class TabState {
  helpMode: HelpMode = HelpMode.INACTIVE;
  helpPage: string = '';
  helpAnchor: string = '';
}

// pinia doesn't allow "state: () => new State()"
// so have to do this... lame
export function tabStateObj() {
  const newState = new TabState();
  return {
    helpMode: newState.helpMode,
    helpPage: newState.helpPage,
    helpAnchor: newState.helpAnchor,
  };
}
