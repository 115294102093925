import { onMounted, onUnmounted, getCurrentInstance, watch, nextTick } from 'vue';
import { useTabStore } from '@custom/base/store';

export type Anchor = { id: string; label: string };

function parseAnchors(tabStore, rootElement: HTMLElement): Anchor[] {
  const anchors: Anchor[] = [];

  const anchorElements = rootElement.querySelectorAll('[data-anchor-id]');

  anchorElements.forEach((element) => {
    const id = element.getAttribute('data-anchor-id');
    const label =
      element.getAttribute('data-anchor-label') || element.textContent?.trim();

    if (id) {
      anchors.push({
        id,
        label: label || id, // Fallback if text content is empty
      });
    }
  });

  // Scroll to the specified help anchor, if any
  if (tabStore.helpAnchor) {
    scrollToAnchor(rootElement, tabStore.helpAnchor);
  }

  return anchors;
}

function scrollToAnchor(rootElement: HTMLElement, anchor: string) {
  setTimeout(() => {
    const target = rootElement.querySelector(`[data-anchor-id="${anchor}"]`);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  }, 100);
}

function setupScrollTracking(
  rootElement: HTMLElement,
  emit: (event: string, payload?: any) => void
) {
  // Cache the anchor elements on setup
  const anchorElements = Array.from(
    rootElement.querySelectorAll('[data-anchor-id]')
  ) as HTMLElement[];

  let currentAnchor: string | null = null;

  const onScroll = () => {
    const scrollPosition = rootElement.scrollTop || window.scrollY;
    const containerHeight =
      rootElement === document.body ? window.innerHeight : rootElement.clientHeight;
    const contentHeight = rootElement.scrollHeight;

    let newAnchor: string | null = null;

    // Check if we're scrolled to the bottom
    if (scrollPosition + containerHeight >= contentHeight - 1) {
      const lastElement = anchorElements[anchorElements.length - 1];
      if (lastElement) {
        newAnchor = lastElement.getAttribute('data-anchor-id') || null;
      }
    } else {
      // Determine the currently visible anchor
      for (const element of anchorElements) {
        const id = element.getAttribute('data-anchor-id');
        if (!id) continue;

        const offsetTop = element.offsetTop;
        const elementHeight = element.offsetHeight;

        // Adjust activation threshold for less aggressive behavior
        if (
          scrollPosition >= offsetTop - containerHeight / 2 &&
          scrollPosition < offsetTop + elementHeight
        ) {
          newAnchor = id;
          break;
        }
      }
    }

    // Ensure the currentAnchor is not cleared out unnecessarily
    if (newAnchor && newAnchor !== currentAnchor) {
      currentAnchor = newAnchor;
      emit('anchorChange', currentAnchor);
    }
  };

  const debouncedScroll = debounce(onScroll, 50);
  rootElement.addEventListener('scroll', debouncedScroll);

  return () => rootElement.removeEventListener('scroll', debouncedScroll);
}

function debounce(func: Function, wait: number) {
  let timeout: number | null = null;

  return function (...args: any[]) {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = window.setTimeout(() => func(...args), wait);
  };
}

export async function clickAnchor(tabStore, anchorId, routeName?: string) {
  const useRoute = routeName || tabStore.$state.helpPage;
  // hack around the case where we nav to an anchor, then manually scroll,
  //  then click back on the same anchor nav
  tabStore.showHelpModal(useRoute);
  await nextTick();
  tabStore.showHelpModal(useRoute, anchorId);
}

export default function helper() {
  const instance = getCurrentInstance();
  if (!instance) {
    throw new Error('No current instance found!');
  }
  const { emit } = instance;

  const tabStore = useTabStore();

  watch(
    () => tabStore.$state.helpAnchor,
    (newAnchor) => {
      if (newAnchor) {
        scrollToAnchor(instance.proxy?.$el?.parentElement as HTMLElement, newAnchor);
      }
      // else scroll to top
      else {
        instance.proxy?.$el?.parentElement?.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  );

  onMounted(() => {
    const rootElement = instance.proxy?.$el?.parentElement as HTMLElement;
    if (!rootElement) {
      console.warn('No parent root element found!');
      return;
    }

    const anchors = parseAnchors(tabStore, rootElement);

    const cleanupScrollTracking = setupScrollTracking(rootElement, emit);

    emit('mounted', anchors);
    emit('anchorChange', tabStore.helpAnchor);

    onUnmounted(() => {
      cleanupScrollTracking();
    });
  });
}
