import { Ref } from 'vue';
import { ColDef, ColType, GQLState, TableClass } from '@service/gql';

export function bodyClass(column: ColDef<TableClass>) {
  if (column.column_type == ColType.float || column.column_type == ColType.int)
    return 'text-right';
  if (column.column_type == ColType.bool) return 'text-center';
  return 'text-left';
}

export function columnStyle(column: ColDef<TableClass>): boolean {
  if (column.column_type == ColType.hidden || (column.hidden && column.hidden == true))
    return true;
  return false;
}

export function filterable(column: ColDef<TableClass>): boolean {
  if (column.column_type == ColType.callback) return false;
  return true;
}

export function sortable(column: ColDef<TableClass>): boolean {
  if (column.column_type == ColType.callback) return false;
  if (column.sortable === false) return column.sortable;
  return true;
}

export function calculatedColumn(column: ColDef<TableClass>, row: object): string {
  if (column.callback) return column.callback(row);
  console.warn(`ERROR: "callback" type column has no calback defined!`, column);
  return 'N/A';
}

export function buttonColumnClass(
  showRemoveButton: boolean,
  showEditButton: boolean,
  showMisc1Button: boolean,
  showMisc2Button: boolean
) {
  let bcount = 0;
  if (showRemoveButton) bcount += 1;
  if (showEditButton) bcount += 1;
  if (showMisc1Button) bcount += 1;
  if (showMisc2Button) bcount += 1;
  if (bcount == 1) return 'button-column-one';
  if (bcount == 2) return 'button-column-two';
  if (bcount == 3) return 'button-column-three';
  if (bcount == 4) return 'button-column-four';
  return 'button-column-hidden';
}

export function generateHeader(column: ColDef<TableClass>): string {
  if (column.header) return column.header;
  return column.field;
}

function getLocStoreKey(prefix: string, tableName: string) {
  let trimSlash = prefix;
  if (trimSlash.startsWith('/')) trimSlash = trimSlash.slice(1);
  if (trimSlash.endsWith('/')) trimSlash = trimSlash.slice(0, -1);
  return ' § ' + trimSlash + ' § ' + tableName;
}

function getSesStoreKey(prefix: string, tableName: string, buildDate: string) {
  let betterDate = new Date(buildDate);
  return (
    getLocStoreKey(prefix, tableName) + ' § ' + betterDate.toISOString().split('.')[0]
  );
}

export function setStorage(
  prefix: string,
  buildDate: string,
  state: Ref<GQLState<TableClass>>
) {
  const locKey = getLocStoreKey(prefix, state.value.tableName);
  console.log(`setStorage.${new Date().getTime()}:`, locKey);
  sessionStorage.setItem(
    getSesStoreKey(prefix, state.value.tableName, buildDate),
    JSON.stringify({
      columns: state.value.columns,
      orderBy: state.value.orderBy,
      localWhere: state.value.localWhere,
    })
  );
  localStorage.setItem(
    locKey,
    JSON.stringify({
      limit: state.value.limit,
      realtime: state.value.realtime,
    })
  );
}

export function getStorage(
  prefix: string,
  buildDate: string,
  state: Ref<GQLState<TableClass>>
) {
  const sesVal = sessionStorage.getItem(
    getSesStoreKey(prefix, state.value.tableName, buildDate)
  );
  let sesObj = {} as any;
  if (sesVal) {
    sesObj = JSON.parse(sesVal);
    state.value.columns = sesObj.columns;
    // re-load an callback methods (or anything else lost in serialization)
    state.value.prepareColumns(state.value.columns);
    state.value.orderBy = sesObj.orderBy;
    state.value.localWhere = sesObj.localWhere;
  }
  const locVal = localStorage.getItem(getLocStoreKey(prefix, state.value.tableName));
  if (locVal) {
    const locObj = JSON.parse(locVal);
    state.value.limit = locObj.limit;
    // this is a newer value so might not exist on old entries, only set if exists
    if (locObj.realtime !== undefined) state.value.realtime = locObj.realtime;
  }
  if (sesObj && sesObj['localWhere']) return true;
  return false;
}
