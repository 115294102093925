import { createRouter, createWebHistory } from 'vue-router';

import Main from '@custom/pe/views/Public/Main.vue';
import UseCases from '@custom/pe/views/Public/UseCases.vue';
import { NavState, SpecialState } from '@custom/pe/views/Public/state';

const routes = [
  {
    path: '/',
    component: Main,
    props: { loadState: NavState.Welcome },
  },
  {
    path: '/smart-factory',
    component: Main,
    props: { loadState: NavState.SmartFactory },
  },
  {
    path: '/use/:slug?',
    component: UseCases,
  },
  {
    path: '/use-cases',
    component: Main,
    props: { loadState: NavState.UseCases },
  },
  {
    path: '/consulting',
    component: Main,
    props: { loadState: NavState.Consulting },
  },
  {
    path: '/privacy',
    component: Main,
    props: { loadState: NavState.Welcome, loadSpecialState: SpecialState.Privacy },
  },
  {
    path: '/legal',
    component: Main,
    props: { loadState: NavState.Welcome, loadSpecialState: SpecialState.Legal },
  },
];

const router = createRouter({
  // @ts-ignore
  history: createWebHistory(import.meta.env.BASE_URL),
  // @ts-ignore
  routes,
});

export default router;
