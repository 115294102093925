<template>
  <div class="guts flex w-full flex-1 items-center justify-center">
    <div
      class="inner m-0 flex flex-1 flex-col items-center justify-center px-4 py-10 lg:my-5"
      :class="{
        isLarge: store.$state.isLargeScreen,
        isSmall: !store.$state.isLargeScreen,
      }"
      style="overflow-x: hidden"
    >
      <div class="relative mb-8 w-full text-center">
        <h2 class="mb-4 text-4xl font-bold text-neutral-100">
          We Do a Lot of Cool Stuff—Here’s a Taste
        </h2>
        <p class="mx-auto max-w-[50rem] text-lg leading-relaxed text-neutral-300">
          From real-time monitoring to inventory optimization, Pocket-E tackles your
          toughest factory challenges. Here’s a glimpse of what we can do—click below to
          explore the full story!
        </p>
      </div>

      <div
        class="grid w-full grid-cols-1 gap-10 md:grid-cols-2"
        style="max-width: 60rem"
      >
        <!-- Performance Monitoring -->
        <div
          v-for="(caseItem, index) in cases"
          :key="index"
          class="flex flex-col items-center text-center"
        >
          <video autoplay muted loop class="mb-4 max-h-40 rounded-md shadow-md">
            <source :src="'/' + caseItem.image" type="video/webm" />
            Sorry, your browser does not support embedded videos.
          </video>
          <div class="mb-2 max-w-[20rem] text-xl font-bold text-slate-100">
            {{ caseItem.text }}
          </div>
          <p class="max-w-[20rem] text-base leading-relaxed text-neutral-300">
            {{ caseItem.name }}
          </p>
        </div>
      </div>

      <div class="mt-8 text-center">
        <a
          href="/use/"
          class="inline-block rounded-md bg-yellow-400 px-8 py-4 text-lg font-bold text-black shadow-md hover:bg-yellow-500"
        >
          Dive Into Our Use Cases <i class="pi pi-arrow-right ml-1"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useStore } from '@projroot/store';

const store = useStore();

const cases = ref([
  {
    text: 'Performance Monitoring',
    image: 'pe_use_1.webm',
    name: 'Keep tabs on any machine metrics, in real-time, all the time.',
  },
  {
    text: 'Real-Time Production',
    image: 'pe_use_2.webm',
    name: 'Automatically report production, scrap, and downtime straight into your ERP.',
  },
  {
    text: 'Zero-Cycle-Count Inventory',
    image: 'pe_use_3.webm',
    name: 'Say goodbye to downtime—never pause production for full inventory counts again.',
  },
  {
    text: 'Trend Analysis & Alerts',
    image: 'pe_use_4.webm',
    name: 'Spot issues before they happen and take action to keep production running.',
  },
]);
</script>

<style scoped lang="scss">
.guts {
  background-color: rgb(37, 37, 37);
}

.inner {
  background-color: rgba(0, 0, 0, 0.9);
}

.isLarge {
  border-radius: 1rem;
  max-width: 80vw !important;
}

.isSmall {
  max-width: 100vw;
}

video {
  max-width: 100%;
  max-height: 10rem;
  border-radius: 0.2rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
}

.text-lg {
  line-height: 1.6;
}

.text-base {
  line-height: 1.5;
}

a {
  transition: background-color 0.3s ease;
}

a:hover {
  background-color: rgb(234, 179, 8); /* Adjust hover color to a brighter yellow */
}
</style>
