<template>
  <img
    :src="src"
    class="max-w-[clamp(0px,_100%,_52rem)] cursor-zoom-in"
    :class="[padClass, bgClass, roundClass]"
    @click="openModal"
  />
  <Transition>
    <div
      v-if="showModal"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90"
      @click.self="closeModal"
    >
      <div class="relative">
        <div class="absolute right-0 top-0">
          <Button @click="closeModal" rounded severity="warn" icon="pi pi-times" />
        </div>
        <img
          :src="src"
          class="big-img max-h-full max-w-full border-4 border-surface-500"
          :class="[padClass, bgClass, roundClass]"
        />
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { ref } from 'vue';

defineProps({
  src: {
    type: String,
    required: true,
  },
  padClass: {
    type: String,
    default: 'p-4',
  },
  bgClass: {
    type: String,
    default: 'bg-zinc-900',
  },
  roundClass: {
    type: String,
    default: 'rounded-lg',
  },
});

const showModal = ref(false);

function openModal() {
  showModal.value = true;
}

function closeModal() {
  showModal.value = false;
}
</script>

<style scoped>
.big-img {
  max-width: 90vw;
  max-height: 90vh;
}
</style>
