<template>
  <div class="guts flex w-full flex-1 items-center justify-center">
    <div
      class="inner flex flex-1 cursor-pointer flex-col items-center gap-4 p-2 py-8 md:rounded lg:m-4 lg:p-12"
      :class="{
        isLarge: store.$state.isLargeScreen,
        isSmall: !store.$state.isLargeScreen,
      }"
      @click="openEfiato"
    >
      <img src="/logo.png" style="width: 40rem; max-width: 80vw" />
      <div class="text-center text-lg italic">
        Once you have a Smart Factory running, you need to crunch the data and figure
        out where you can start making real-world improvements.
      </div>
      <div class="text-center text-lg italic">
        Use Efiato to bring your factory into perfect harmony!
      </div>
      <video autoplay muted loop>
        <source :src="'/pe_efiato.webm'" type="video/webm" />
        Sorry, your browser does not support embedded videos.
      </video>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';

import { useStore } from '@projroot/store';
const store = useStore();

onMounted(async () => {});

function openEfiato() {
  const url = 'https://www.efiato.com';
  window.open(url, '_blank');
}
</script>

<style scoped lang="scss">
.guts {
  background-image: url('/efiato_enterprise_hum2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.inner {
  background-color: rgba(9, 0, 47, 0.9);
  transition: all 1s;
  &:hover {
    background-color: rgb(13, 0, 69);
  }
}
.isLarge {
  max-width: 50rem;
}

.isSmall {
  max-width: 100vw;
}

video {
  max-width: 100%;
  // height: auto;
  max-height: 50vh;
  border-radius: 0.2rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
}
</style>
