<template>
  <footer
    class="flex flex-col-reverse items-center justify-center gap-2 px-2 py-1 text-xs font-bold md:flex-row"
    style="color: rgba(255, 255, 255, 0.361)"
  >
    <div>
      ©
      <a href="https://semi-legitimate.com" target="_blank"
        >Semi-Legitimate Software, LLC.</a
      >
      All rights reserved.
    </div>
    <div class="hidden md:block">|</div>
    <div class="flex gap-2">
      <div class="cursor-pointer" @click="showLegal = true">Legal</div>
      <div>|</div>
      <div class="cursor-pointer" @click="showPrivacy = true">Privacy</div>
    </div>
  </footer>
  <Dialog
    v-model:visible="showLegal"
    header="Legal"
    :breakpoints="{ '900px': '75vw', '640px': '100vw' }"
    :style="{ width: '40rem' }"
    :draggable="true"
    :keepInViewPort="true"
    :minX="0"
    :minY="0"
    :modal="true"
    :closable="true"
  >
    <div class="flex flex-col gap-2 px-6 py-2 text-left">
      <div class="text-lg font-bold">semi-legitimate.com</div>
      <div>
        "industrial remains" photo by Till Krech licensed under CC Attribution 2.0
        Generic
      </div>
      <div class="mt-2 text-lg font-bold">slsgroup.org</div>
      <div>
        "Glass and Steel (HONG KONG/ARCHITECTURE) IX" photo by Chi King licensed under
        CC Attribution 2.0 Generic
      </div>
      <div>
        "jefferson ave" photo by pal bica licensed under CC Attribution 2.0 Generic /
        Slight Blur and Crop Added
      </div>
    </div>
  </Dialog>
  <Dialog
    v-model:visible="showPrivacy"
    header="Privacy"
    :breakpoints="{ '900px': '75vw', '640px': '100vw' }"
    :style="{ width: '40rem' }"
    :draggable="true"
    :keepInViewPort="true"
    :minX="0"
    :minY="0"
    :modal="true"
    :closable="true"
  >
    <div class="flex flex-col gap-2 px-6 py-2 text-left">
      <iframe src="/pe_privacy.html" class="min-h-60 bg-white" />
    </div>
  </Dialog>
</template>

<script setup lang="ts">
const showLegal = defineModel('showLegal', { type: Boolean, default: false });
const showPrivacy = defineModel('showPrivacy', { type: Boolean, default: false });
</script>
