<template>
  <div
    :class="rootClass"
    @click="toggleCheck"
    :data-p-checked="isChecked"
    :data-p-disabled="disabled"
  >
    <input
      ref="checkboxInput"
      :id="inputId"
      type="checkbox"
      :class="inputClassComputed"
      :checked="isChecked"
      :disabled="disabled"
      @click.stop="toggleCheck"
      @change="onInputChange"
    />
    <div :class="boxClassComputed">
      <slot name="icon" :checked="isChecked">
        <CheckIcon v-if="isChecked" :class="iconClassComputed" />
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import CheckIcon from '@primevue/icons/check';

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  invalid: Boolean,
  inputId: String,
  inputClass: [String, Object],
  slsMode: {
    type: String as () => 'big' | 'medium' | 'normal',
    default: 'normal',
  },
  disabled: Boolean,
});

const emit = defineEmits(['update:modelValue', 'click']);

const isChecked = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    isChecked.value = newValue;
  }
);

const rootClass = computed(() => [
  'relative inline-flex align-bottom w-6 h-6 cursor-pointer select-none',
  props.slsMode === 'big'
    ? 'min-h-13 min-w-13'
    : props.slsMode === 'medium'
      ? 'min-h-10 min-w-10'
      : 'h-6 w-6',
  {
    'opacity-50 cursor-not-allowed': props.disabled,
  },
]);

const boxClassComputed = computed(() => [
  'flex items-center justify-center rounded-md border-2 transition-colors duration-200',
  props.slsMode === 'big'
    ? 'min-h-13 min-w-13'
    : props.slsMode === 'medium'
      ? 'min-h-10 min-w-10'
      : 'h-6 w-6',
  {
    'border-surface-200 bg-surface-0 dark:border-surface-700 dark:bg-surface-900':
      !isChecked.value && !props.invalid && !props.disabled,
    'border-primary bg-primary': isChecked.value,
    'border-red-500 dark:border-red-400': props.invalid,
    'peer-hover:border-primary': !props.disabled && !isChecked.value && !props.invalid,
    'peer-hover:bg-primary-emphasis peer-hover:border-primary-emphasis':
      !props.disabled && isChecked.value,
    'cursor-default opacity-60': props.disabled,
  },
]);

const inputClassComputed = computed(() => [
  'peer w-full h-full absolute top-0 left-0 z-10 p-0 m-0 opacity-0 rounded-md outline-none border-2 border-surface-200 dark:border-surface-700 appearance-none cursor-pointer',
]);

const iconClassComputed = computed(() => [
  'text-base leading-none transition-all duration-200',
  props.slsMode === 'big'
    ? 'h-12 w-10'
    : props.slsMode === 'medium'
      ? 'h-8 w-8'
      : 'h-4 w-4',
  {
    'text-white dark:text-surface-900': isChecked.value,
  },
]);

function toggleCheck() {
  if (props.disabled) return;
  const newModelValue = !isChecked.value;
  emit('update:modelValue', newModelValue);
  isChecked.value = newModelValue;
}

function onInputChange(event: Event) {
  const target = event.target as HTMLInputElement;
  emit('update:modelValue', target.checked);
  isChecked.value = target.checked;
}
</script>

<style scoped></style>
