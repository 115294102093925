import { computed, Ref } from 'vue';

interface UseDynamicClassesProps {
  invalid: Ref<boolean>;
  disabled: Ref<boolean>;
}

// this mimicks the behavior or lara.inputtext

export function useDynamicClasses(props: UseDynamicClassesProps) {
  return computed(() => [
    // Font
    'leading-[normal]',

    // Spacing
    'm-0',

    //SLSADD - Adding spacing adjustments
    'w-full min-h-13 relative',

    // Size
    'p-3',

    // Shape
    'rounded-md',

    // Colors
    'text-surface-950 dark:text-surface-100',
    'placeholder:text-surface-400 dark:placeholder:text-surface-500',
    'bg-surface-0 dark:bg-surface-900',
    'border',
    { 'border-surface-300 dark:border-surface-600': !props.invalid.value },

    // Invalid State
    { 'border-red-500 dark:border-red-400': props.invalid.value },

    // States
    {
      'hover:border-primary': !props.disabled.value && !props.invalid.value,
      'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-500/50 dark:focus:ring-primary-400/50 focus:z-10':
        !props.disabled.value,
      'opacity-60 select-none pointer-events-none cursor-default': props.disabled.value,
    },

    // Misc
    'appearance-none',
    'transition-colors duration-200',
  ]);
}
