<template>
  <span
    class="cursor-pointer text-blue-500 transition-all hover:text-blue-700"
    @click="doClick"
    >&nbsp; <slot></slot>&nbsp;
  </span>
</template>

<script setup lang="ts">
import { useTabStore } from '@custom/base/store';
import { clickAnchor } from '@custom/base/docs/helper';
const tabStore = useTabStore();

const props = defineProps({
  anchorId: {
    type: String,
    required: true,
  },
  docTitle: {
    type: String,
    default: undefined,
  },
});

async function doClick() {
  await clickAnchor(tabStore, props.anchorId, props.docTitle);
}
</script>
<style scoped></style>
