import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { baseActions } from './actions';
import { State, stateObj } from './state';
import { baseTabActions } from './tabActions';
import { tabStateObj, TabState } from './tabState';

const useBaseStore = defineStore({
  id: `${import.meta.env.VITE_ENVNAME}:${import.meta.env.VITE_BUILDDIR}`,
  state: () => stateObj() as State,
  actions: {
    ...baseActions,
    // in each custom store, you can define custom actions here
  },
  persist: true,
});

export function useStore() {
  return useBaseStore();
}

const tabStoreKey = `TAB_${import.meta.env.VITE_ENVNAME}:${import.meta.env.VITE_BUILDDIR}`;
const tabStore = defineStore({
  id: tabStoreKey,
  state: () =>
    useStorage(
      tabStoreKey,
      tabStateObj(),
      window.sessionStorage
    ) as unknown as TabState,
  actions: {
    ...baseTabActions,
  },
});

export function useTabStore() {
  return tabStore();
}
